import BlogMediaDetails from '../components/blog-media/mediablog'
import React, { useEffect } from 'react'
const Blogdetails = () => {
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo({top:0,left:0, behavior:'instant'});
    }, 260);
  }, [])
  return <BlogMediaDetails type='blog' />
}

export default Blogdetails
