import './data-coverage.css'
import CountUp from 'react-countup';
const OurdataCard = ({ end ,title, image, start,duration }) => {
  return (
    <>
      <div className='data-card'>
        <div className='data-card-title'>
          <div className='number-data'>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
              <CountUp
               className='countUpfont'
                  start={start}
                  end={end}
                  duration={duration}
                  separator=","
                  enableScrollSpy={true}
                  scrollSpyDelay={500}
                  scrollSpyOnce={true}
                />
            </span>
          </div>
          {title}
        </div>
        <img src={image} alt='data-graphic' />
      </div>
    </>
  )
}

export default OurdataCard
