import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Accordion from 'react-bootstrap/Accordion'

import '../components/terms-services/terms-services.css'

import TermsServices from '../components/terms-services/terms-services-banner'
import TermsCollapse from '../components/terms-services/terms-collapse'
import { useEffect } from 'react'
const Salverystatement = () => {
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo(0, 0)
    }, 400);
  }, [])
  return (
    <section className='main'>
      <section className='terms-sec'>
        <Container>
          <Row>
            <TermsServices title={'Modern Day Slavery Statement'} />
          </Row>

          <Row>
            <section className='terms-content'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item eventKey='0'>
                  <TermsCollapse
                    Header={'Modern Day Slavery Statement'}
                    Details={
                      <>
                        <p>
                          Modern slavery is a crime and a violation of
                          fundamental human rights. It takes various forms, such
                          as slavery, servitude, forced and compulsory labour
                          and human trafficking, all of which have in common the
                          deprivation of a person’s liberty by another to
                          exploit them for personal or commercial gain.
                        </p>
                        <p>
                          The Company has a zero-tolerance approach to modern
                          slavery, and we are committed to acting ethically and
                          with integrity in all our business dealings and
                          relationships and to implementing and enforcing
                          effective systems and controls to ensure modern
                          slavery is not taking place anywhere in our own
                          business or in any of our supply chains.''
                        </p>
                        <p>
                          We are also committed to ensuring there is
                          transparency in our own business and in our approach
                          to tackling modern slavery throughout our supply
                          chains, consistent with our disclosure obligations
                          under the Modern Slavery Act 2015.
                        </p>
                        <p>
                          We expect the same high standards from all our
                          contractors, suppliers, and other business partners,
                          and as part of our contracting processes, in the
                          coming year we will include specific prohibitions
                          against the use of forced, compulsory or trafficked
                          labour, or anyone held in slavery or servitude,
                          whether adults or children, and we expect that our
                          suppliers will hold their own suppliers to the same
                          high standards.
                        </p>
                        <p>
                          This policy applies to all persons working for us or
                          on our behalf in any capacity, including employees at
                          all levels, directors, officers, agency workers,
                          seconded workers, volunteers, interns, agents,
                          contractors, external consultants, third-party
                          representatives, and business partners.
                        </p>
                        <p>
                          This policy does not form part of any employee’s
                          contract of employment and we may amend it at any
                          time.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                  <TermsCollapse
                    Header={'Responsibility for the Policy'}
                    Details={
                      <>
                        <p>
                          You must ensure that you read, understand, and comply
                          with this policy.
                        </p>
                        <p>
                          The prevention, detection and reporting of modern
                          slavery in any part of our business or supply chains
                          is the responsibility of all those working for us or
                          under our control.
                        </p>
                        <p>
                          You are required to avoid any activity that might lead
                          to, or suggest, a breach of this policy. You must
                          notify your line manager OR a company Director as soon
                          as possible if you believe or suspect that a conflict
                          with this policy has occurred or may occur in the
                          future.
                        </p>
                        <p>
                          You are encouraged to raise concerns about any issue
                          or suspicion of modern slavery in any parts of our
                          business or supply chains of any supplier tier at the
                          earliest possible stage. If you believe or suspect a
                          breach of this policy has occurred or that it may
                          occur, you must notify your line manager or company
                          Director OR report it in accordance with our
                          Whistleblowing Policy as soon as possible.
                        </p>
                        <p>
                          You should note that where appropriate, and with the
                          welfare and safety of local workers as a priority, we
                          will give support and guidance to our suppliers to
                          help them address coercive, abusive, and exploitative
                          work practices in their own business and supply
                          chains.
                        </p>
                        <p>
                          If you are unsure about whether a particular act, the
                          treatment of workers more generally, or their working
                          conditions within any tier of our supply chains
                          constitutes any of the various forms of modern
                          slavery, raise it with your line manager or company
                          Director.
                        </p>
                        <p>
                          We aim to encourage openness and will support anyone
                          who raises genuine concerns in good faith under this
                          policy, even if they turn out to be mistaken. We are
                          committed to ensuring no one suffers any detrimental
                          treatment as a result of reporting in good faith their
                          suspicion that modern slavery of whatever form is or
                          may be taking place in any part of our own business or
                          in any of our supply chains.
                        </p>
                        <p>
                          Detrimental treatment includes dismissal, disciplinary
                          action, threats, or other unfavourable treatment
                          connected with raising a concern.
                        </p>
                        <p>
                          If you believe that you have suffered any such
                          treatment, you should inform your line manager
                          immediately. If the matter is not remedied, and you
                          are an employee, you should raise it formally using
                          our Grievance Procedure, which can be found in the
                          current employee handbook.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                  <TermsCollapse
                    Header={'Communication & awareness of this policy'}
                    Details={
                      <>
                        <p>
                          Training on this policy, and on the risk our business
                          faces from modern slavery in its supply chains, forms
                          part of the induction process for all individuals who
                          work for us, and updates will be provided using
                          established methods of communication between the
                          business and you.
                        </p>
                        <p>
                          Our zero-tolerance approach to modern slavery must be
                          communicated to all suppliers, contractors, and
                          business partners at the outset of our business
                          relationship with them and reinforced as appropriate
                          thereafter.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                  <TermsCollapse
                    Header={'Breaches of this policy'}
                    Details={
                      <>
                        <p>
                          Any employee who breaches this policy will face
                          disciplinary action, which could result in dismissal
                          for misconduct or gross misconduct. We may terminate
                          our relationship with other individuals and
                          organisations working on our behalf if they breach
                          this policy.
                        </p>
                        <p>This statement will be reviewed annually.</p>
                        <p>
                          Should you require any further information on our
                          Statement on Modern Slavery please contact Lee Gao
                          Rui, Compliance Lead on{' '}
                          <a href='mailto:compliance@navigatecommodities.com'>
                            compliance@navigatecommodities.com
                          </a>
                        </p>
                        <p>
                          This statement has been published in accordance with
                          Modern Slavery Act 2015 section 54 (1) and approved by
                          the Navigate Commodities Pte. Ltd. Board of Directors
                          for the financial year ending December 2021.
                        </p>
                        <p>
                          Atilla Widnell, Managing Director, Navigate
                          Commodities Pte. Ltd.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
              </Accordion>
            </section>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Salverystatement
