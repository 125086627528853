import BlogMediaDetails from '../components/blog-media/mediablog'
import { useEffect } from 'react'
const Mediacoveragedetails = () => {
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo({top:0,left:0, behavior:'instant'})
      
    }, 240);
  }, [])
  return <BlogMediaDetails type='media'></BlogMediaDetails>
}

export default Mediacoveragedetails
