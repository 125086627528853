
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './innertopbanner.css';
// import Animation_mp from '../../images/home/videos/animation.mp4';


    const Innertopbanner= ({header,pradetails,image})=>{
    return  (
            <Row className= "align-items-center">
                <Col md = {6} className = "mb-3 mb-md-0">
                    <h1>
                        {header}
                    </h1>
                    <p className="banner-pra">
                        {pradetails}
                    </p>
                    <Row className = "align-items-center d-none d-md-flex">
                        <Col xs = "12">
                            <Link to = "/request-demo" className="btn-main">
                                Request Demo
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col md = {{span: 5, offset: 1}} className = "text-end">
                    {/* <img className='w-75' src = {Animationgif} alt = "banner-animation"/> */}
                    {/* <video width = "100%" height = "auto" loop autoPlay = {true} muted = {true}>
                        <source src={Animation_mp} type="video/mp4"/>
                    </video> */}
                    {image}
                    {/* <img className = "innerbanner-img" src = {image} alt = "banner-graphic"/> */}
                </Col>
            </Row>
        );
    }

export default Innertopbanner