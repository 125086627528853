import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Demobanner from '../demobanner/Demobanner'
import Postbanner from '../blog-post/post-banner-img'
import Post from '../blog-post/post'
import Categories from '../blog-post/categories-search'

import '../blog-post/blog-post.css'
import blog_logo from '../../images/blogs/reuters2.png'
import Placeholderimg from '../../images/blogs/placeholderimg.jpg'
import prev from '../../images/testimonials/navprev.svg'
import next from '../../images/testimonials/navnext.svg'
import { mediaApi } from './api'
import { utilities } from '../utlilities/utils'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Mediapost from '../blog-post/media-post'
import Blogblank from '../../pages/blog-blank'
import axios from '../../configuration/axios'

const BlogMedia = ({ type }) => {
  const [blog, setBlog] = useState([])
  const [featureBlog, setFeatureBlog] = useState({})
  const [featureMedia, setFeatureMedia] = useState({})

  const [currentPage, setCurrentPage] = useState(0)
  const [pages, setNumberOfPages] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [totalNumberOfPages, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [currentPagination, setCurrentPart] = useState(0)
  const [setOfPages, setPages] = useState(5)
  const [nextPrevious, setNextPrevious] = useState(0)
  let numberPerScroll = 5

  const [totalpages, setTotalpages] = useState()
  const imageUrl = process.env.REACT_APP_IMAGE_URL

  useEffect(() => {
    getBlogMedia(1, '')
    getFeaturedBlogs()

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    }, 400)
  }, [])
  // console.log('feat', featureBlog?.data.title)
  //  <-------------------------------------User Define Function----------------->

  const searchMediaBlog = async query => {
    setSearchQuery(query)

    getBlogMedia(1, query)
  }

  const nextPages = () => {
    const totalNumber = totalNumberOfPages - setOfPages

    if (setOfPages >= totalNumberOfPages) {
      return
    }

    if (totalNumber >= 5) {
      setPages(setOfPages + 5)
      setCurrentPart(currentPagination + 5)
    } else {
      setPages(setOfPages + totalNumber)
      setCurrentPart(currentPagination + 5)
    }
  }

  const previousPages = () => {
    const totalNumber = totalNumberOfPages - setOfPages

    if (setOfPages <= 0 && currentPagination <= 0) {
      setCurrentPage(0)
      return
    }
    if (setOfPages > 5 && setOfPages <= totalNumberOfPages) {
      setPages(currentPagination)
      setCurrentPart(currentPagination - 5)
    } else {
      setPages(currentPagination)
      // setCurrentPart(currentPagination);
      setCurrentPart(currentPagination - 5)
    }
  }

  const naviagetWithArrow = (number, query) => {
    setSearchQuery(query)
    getBlogMedia(number, query)
  }

  const getBlogMedia = (index, query = '') => {
    setLoading(true)
    let url = null
    if (type == 'media') {
      if (query != '') {
        url = `/website/media-coverages/search?page=${index}&query=${query}`
      } else {
        url = `/website/media-coverages?page=${index}`
      }
    } else {
      if (query != '') {
        url = `/website/blogs/search?page=${index}&query=${query}`
      } else {
        url = `/website/blogs?page=${index}`
      }
    }
    mediaApi?.getBlogMedia(url, (err, data) => {
      if (err == null) {
        const totalNumber = Math.ceil(
          data?.data?.meta?.total / data?.data?.meta?.per_page
        )
        setCurrentPage(data?.data?.meta?.current_page)
        setTotal(totalNumber)
        setTotalpages(data?.data?.meta?.last_page)
        let pages = []
        for (let i = 0; i < totalNumber; i++) {
          pages.push(
            <li
              className='nav-item'
              onClick={() => {
                getBlogMedia(i + 1, searchQuery)
              }}
            >
              {type == 'blog' ? (
                <Link
                  to='/blog'
                  className={
                    data?.data?.meta?.current_page == i + 1 ? 'active' : ''
                  }
                >
                  {i + 1}
                </Link> //else
              ) : (
                <Link
                  to='/media-coverage'
                  className={
                    data?.data?.meta?.current_page == i + 1 ? 'active' : ''
                  }
                >
                  {i + 1}
                </Link>
              )}
            </li>
          )
        }
        setNumberOfPages(pages)

        const blogsdata = data?.data?.data?.map(item => {
          if (item?.updated_at != null) {
            return {
              ...item,
              updated_at: utilities?.convertDateToLocal(item?.updated_at),
              content:
                item?.content == null
                  ? ' '
                  : ReactHtmlParser(item?.content?.substring(0, 200) + '...')
            }
          } else {
            return {
              ...item,
              updated_at: '',
              content:
                item?.content == null
                  ? ' '
                  : ReactHtmlParser(item?.content?.substring(0, 200) + '...')
            }
          }
        })
        // if (blogsdata.length > 0) {
        //   //sort updated and published media blog
        //   function sortFunction(a, b) {
        //     var dateA = new Date(a?.date).getTime();
        //     var dateB = new Date(b?.date).getTime();
        //     return dateA > dateB ? 1 : -1; //if datea > dateb return 1 i.e it will come after dateb
        //   }
        //   // console.log("sorted",blogsdata.sort(sortFunction))
        //   blogsdata.sort(sortFunction);
        //   setFeatureMedia(blogsdata[blogsdata.length - 1]);
        // } else {
        //   setFeatureMedia({});
        // }
        setFeatureMedia(blogsdata[0])
        setBlog(blogsdata)
      } else {
        setBlog([])
      }
    })

    setLoading(false)
    window?.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }

  //featured blogs
  const getFeaturedBlogs = async () => {
    try {
      const res = await axios.get('website/blogs/featured')
      if (res?.data) {
        console.log(res.data)
        setFeatureBlog(res?.data?.data)
      }
    } catch (error) {
      setFeatureBlog({})
    }
  }

  // <--------------------------------------End of User Deine Function------------------>
  return blog?.length == 0 && loading == false ? (
    <Blogblank searchMediaBlog={searchMediaBlog} query={searchQuery} />
  ) : type == 'blog' ? (
    <section className='main'>
      {featureBlog == 'No featured blog available!' ? null : (
        <section className='main-banner blog-banner'>
          <Container>
            <Row className='align-items-center'>
              <Col md={{ order: 'last', span: '6' }}>
                {/* <Postbanner image={blog_logo} /> */}
                <Postbanner
                  image={
                    featureBlog?.image !== null &&
                    featureBlog?.image !== undefined &&
                    featureBlog?.image.includes('https')
                      ? featureBlog?.image
                      : featureBlog?.image == null
                      ? Placeholderimg
                      : `${imageUrl}${featureBlog?.image}`
                  }
                />
              </Col>

              <Col md={{ order: 'first', span: '6' }}>
                <div className='feature-badge'>Featured</div>
                <h1>{featureBlog?.title}</h1>
                <div className='blog-date'>
                  {utilities?.convertDateToLocal(featureBlog?.date)}
                </div>
                <p className='blog-banner-details'>
                  {ReactHtmlParser(
                    featureBlog?.content?.substring(0, 200) + '...'
                  )}
                </p>
                <Link
                  to={`/${type}-details/${
                    featureBlog?.id
                  }/${featureBlog?.title?.replaceAll(' ', '-')}`}
                  className='btn-main'
                >
                  {type == 'blog' ? 'Read More' : 'View Now'}
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className='blog-post'>
        <Container>
          <Row>
            <Col md={9} xl={10} className='order-last order-md-first'>
              <Row className='post-row'>
                {blog?.map(item => {
                  return (
                    item?.is_featured !=="1" && (
                      <Col lg={4} md={6}>
                        <Post
                          // image={blog_logo}
                          image={
                            item?.image !== null &&
                            item?.image !== undefined &&
                            item?.image.includes('https')
                              ? item?.image
                              : item?.image == null
                              ? Placeholderimg
                              : `${imageUrl}${item?.image}`
                          }
                          title={item?.title}
                          pdate={utilities?.convertDateToLocal(item?.date)}
                          link={`/${type}-details/${
                            item?.id
                          }/${item?.title?.replaceAll(' ', '-')}`}
                        />
                      </Col>
                    )
                  )
                })}
              </Row>
              <Row>
                <Col sm={12}>
                  <div className='pagination-btn'>
                    <ul className='nav justify-cotnent-center align-items-center'>
                      {currentPage > 1 ||
                      (currentPage >= 5 && setOfPages == totalNumberOfPages) ? (
                        <li className='nav-item'>
                          <button
                            className='nextprev prev'
                            onClick={() => {
                              if (currentPage > 1) {
                                let noewPage = currentPage - 1
                                naviagetWithArrow(noewPage, searchQuery)
                              }
                            }}
                          >
                            <img src={prev} alt='nav-prev' />
                          </button>
                        </li>
                      ) : null}
                      {/* setTotalpages==5 */}
                      {
                        // currentPage >= 5 &&
                        // setOfPages == totalNumberOfPages &&
                        // currentPagination !== 0 ?
                        nextPrevious > 0 ? (
                          <li className='nav-item'>
                            <button
                              className='nextprev prev'
                              style={{
                                display:
                                  currentPagination == 0
                                    ? 'none !important'
                                    : 'block'
                              }}
                              onClick={() => {
                                setNextPrevious(nextPrevious - 1)
                                previousPages()
                              }}
                            >
                              Prev
                            </button>
                          </li>
                        ) : null
                      }
                      {/* {pages?.slice(currentPagination, setOfPages)?.map(e => {
                        return e
                      })} */}
                      {pages?.slice(currentPagination, setOfPages)?.map(e => {
                        return e
                      })}
                      {
                        // currentPage < totalpages &&
                        // setOfPages < totalNumberOfPages
                        nextPrevious >
                        Math.floor(totalpages / numberPerScroll) ? (
                          <li className='nav-item'>
                            <button
                              className='nextprev next'
                              onClick={() => {
                                setNextPrevious(nextPrevious + 1)
                                nextPages()
                              }}
                            >
                              Next
                            </button>
                          </li>
                        ) : null
                      }

                      {currentPage < totalpages ? (
                        <li className='nav-item'>
                          <button
                            className='nextprev next'
                            onClick={() => {
                              if (currentPage < totalNumberOfPages) {
                                let noewPage = currentPage + 1
                                naviagetWithArrow(noewPage, searchQuery)
                              }
                            }}
                          >
                            <img src={next} alt='nav-next' />
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              md={3}
              xl={2}
              className='order-firt order-md-last mb-4 mb-md-0'
            >
              <Categories
                searchMediaBlog={searchMediaBlog}
                query={searchQuery}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Demobanner />
    </section>
  ) : (
    <section className='main'>
      <section className='main-banner blog-banner'>
        <Container>
          <Row className='align-items-center'>
            <Col md={{ order: 'last', span: '6' }}>
              <Postbanner
                image={
                  featureMedia?.image !== null &&
                  featureMedia?.image !== undefined &&
                  featureMedia?.image.includes('https')
                    ? featureMedia?.image
                    : featureMedia?.image == null
                    ? Placeholderimg
                    : `${imageUrl}${featureMedia?.image}`
                }
              />
            </Col>
            <Col md={{ order: 'first', span: '6' }}>
              <div className='feature-badge'>Latest</div>
              <h1>{featureMedia?.title}</h1>
              <div className='blog-date'>
                {utilities?.convertDateToLocal(featureMedia?.date)}
              </div>
              <p className='blog-banner-details'>{featureMedia?.content}</p>
              {type !== 'blog' && featureMedia?.video_url ? (
                <a
                  href={featureMedia?.video_url}
                  target='_blank'
                  className='btn-main'
                >
                  View Now
                </a>
              ) : (
                <Link
                  to={`/${type}-details/${
                    featureMedia?.id
                  }/${featureMedia?.title?.replaceAll(' ', '-')}`}
                  className='btn-main'
                >
                  {type == 'blog' ? 'Read More' : 'View Now'}
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <section className='blog-post'>
        <Container>
          <Row>
            <Col md={9} xl={10} className='order-last order-md-first'>
              {blog?.slice(1, blog?.length - 1)?.map(item => {
                return (
                  <Mediapost
                    header={item?.title}
                    title={item?.content}
                    video_url={item?.video_url}
                    pdate={utilities?.convertDateToLocal(item?.date)}
                    // image={market_logo}
                    image={
                      item?.image !== null &&
                      item?.image !== undefined &&
                      item?.image.includes('https')
                        ? item?.image
                        : item?.image == null
                        ? Placeholderimg
                        : `${imageUrl}${item?.image}`
                    }
                    link={`/${type}-details/${
                      item?.id
                    }/${item?.title?.replaceAll(' ', '-')}`}
                  />
                )
              })}
              {pages?.length > 1 ? (
                <Row>
                  <Col sm={12}>
                    <div className='pagination-btn'>
                      <ul className='nav justify-cotnent-center align-items-center'>
                        {currentPage > 1 ? (
                          <li className='nav-item'>
                            <button
                              className='nextprev prev'
                              onClick={() => {
                                if (currentPage > 1) {
                                  let noewPage = currentPage - 1
                                  naviagetWithArrow(noewPage, searchQuery)
                                }
                              }}
                            >
                              <img src={prev} alt='nav-prev' />
                            </button>
                          </li>
                        ) : null}
                        {
                          // currentPage >= 5 &&
                          // setOfPages <= totalNumberOfPages &&
                          // currentPagination !== 0 ?
                          nextPrevious > 0 ? (
                            <li>
                              <button
                                className='nextprev prev'
                                onClick={() => {
                                  setNextPrevious(nextPrevious - 1)
                                  previousPages()
                                }}
                              >
                                Prev
                              </button>
                            </li>
                          ) : null
                        }
                        {/* working2 */}
                        {/* {pages
                          ?.slice(currentPagination, setOfPages)
                          ?.map((e) => {
                            return e;
                          })} */}
                        {pages?.slice(currentPagination, setOfPages)?.map(e => {
                          return e
                        })}
                        {
                          // currentPage < totalpages &&
                          // setOfPages < totalNumberOfPages
                          nextPrevious <
                          Math.floor(totalpages / numberPerScroll) ? (
                            <li>
                              <button
                                className='nextprev next'
                                onClick={() => {
                                  setNextPrevious(nextPrevious + 1)
                                  nextPages()
                                }}
                              >
                                Next
                              </button>
                            </li>
                          ) : null
                        }
                        {currentPage < totalpages ? (
                          <li className='nav-item'>
                            <button
                              className='nextprev next'
                              onClick={() => {
                                if (currentPage < totalNumberOfPages) {
                                  let noewPage = currentPage + 1
                                  naviagetWithArrow(noewPage, searchQuery)
                                }
                              }}
                            >
                              <img src={next} alt='nav-next' />
                            </button>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col
              md={3}
              xl={2}
              className='order-firt order-md-last mb-4 mb-md-0'
            >
              <Categories
                searchMediaBlog={searchMediaBlog}
                query={searchQuery}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default BlogMedia
