import Accordion from 'react-bootstrap/Accordion'

const TermsCollapse = ({ Header, Details, id }) => {
  return (
    <>
      <Accordion.Header>{Header}</Accordion.Header>
      <Accordion.Body className='ps-0 pt-0' id={id}>
        {Details}
      </Accordion.Body>
    </>
  )
}

export default TermsCollapse
