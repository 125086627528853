import axios from '../../configuration/axios'

export const testimonial_api = {
  getTestimonial: callback => {
    axios
      .get('/website/testimonials', {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(data => {
        callback(null, data)
      })
      .catch(err => {
        callback(err, null)
      })
  }
}
