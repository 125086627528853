

import './data-coverage.css';

const DataCard = ({image,title})=>{
    return(
        <>
            <div className = "data-card">
                <img src = {image} alt = "data-graphic"/>
                <div className = "data-card-title">
                    {title}
                </div>
            </div>
        </>
    )
}

export default DataCard;