import { Link } from 'react-router-dom'

const Post = ({ image, title, pdate, link }) => {
  return (
    <div className='postcard'>
       <Link to={link}>
        <div className='post-img'>
          <img src={image} alt='post-img' />
        </div>
      </Link>
      <Link to={link} className="post-title" > {title}</Link>
      <div className='postdate'>{pdate}</div>
      <Link to={link} className='btn-main'>
        Read More
      </Link>
    </div>
  )
}

export default Post
