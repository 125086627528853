
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './services.css';


    const Tradeservices= ({image,header,pra,button})=>{
    return  (
            <div className = "tradesection mb-5 mb-lg-5">
                <Row className="align-items-center">
                    <Col md = {6} lg = {7} xxl = {8} className = "mb-3 mb-md-0">
                        <h2>{header}</h2>
                        <p>
                            {pra}
                        </p>
                        {button}
                    </Col>
                    <Col md = {6} lg = {5} xxl = {4} className = "mb-3 mb-md-0">
                            <img className="trade-service-img" src = {image} alt = "supply-demand"/>
                    </Col>
                </Row>
            </div>
        );
    }

export default Tradeservices