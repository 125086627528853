
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './datafeatures.css';


    const DataFeatures= ({header, Pradetails})=>{
    return  (
            <Container>
                <Row>
                    <Col xs = {12}>
                        <h2>
                            {header}
                        </h2>
                        <p className="banner-pra">
                           {Pradetails}
                        </p>
                    </Col>
                </Row>
            </Container>
        );
    }

export default DataFeatures