import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import Demobanner from "../components/demobanner/Demobanner";
import Ourstory from "../components/about/ourstory";
import Leadership from "../components/about/leadership";
import Ourcompany from "../components/about/company";
import MediaCoverage from "../components/about/mediaCoverage";

const About = () => {

    // useEffect(() => {
    //     window?.scrollTo(0, 0)
    // }, [])
    const { id } = useParams()
    useEffect(() => {
        console.log(id)
        if (id != null && id != undefined) {
            setTimeout(() => {
                document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' })
            }, 300);

        }
    }, [id])

    return (
        <section className="main">

            <Ourstory />

            <Leadership />

            <Ourcompany />

            {/* <MediaCoverage/> */}

            <Demobanner />

        </section>
    );
}

export default About;