import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Accordion from 'react-bootstrap/Accordion'
import Table from 'react-bootstrap/Table'
import $ from 'jquery'
import '../components/terms-services/terms-services.css'

import TermsServices from '../components/terms-services/terms-services-banner'
import TermsCollapse from '../components/terms-services/terms-collapse'
import { useEffect } from 'react'
import { useState } from 'react'
const Privacypolicy = () => {
  const [currentId, setId] = useState('#header0')
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo(0, 0);
    }, 400);
  }, [])

  useEffect(() => {
    $('.collapse').on('shown.bs.collapse', function (e) {
      var $card = $(this).closest('.accordion-item')
      var $open = $($(this).data('parent')).find('.collapse.show')

      var additionalOffset = 0
      if (
        $card.prevAll().filter($open.closest('.accordion-item')).length !== 0
      ) {
        additionalOffset = $open.height()
      }
      $('html,body').animate(
        {
          scrollTop: $card.offset().top - additionalOffset
        },
        500
      )
    })
  }, [currentId])

  const setCurrentId = id => {
    setId(`#header${id}`)
  }

  return (
    <section className='main'>
      <section className='terms-sec'>
        <Container>
          <Row>
            <TermsServices title={'Privacy Policy'} />
          </Row>

          <Row>
            <section className='terms-content'>
              <Accordion defaultActiveKey='0' flush>
                <Accordion.Item
                  eventKey='0'
                  id='header0'
                  onClick={() => {
                    setCurrentId(0)
                  }}
                >
                  <TermsCollapse
                    Header={'Our Privacy Commitments'}
                    Details={
                      <>
                        <p>
                          Navigate Commodities Pte. Ltd. (“we”) respect the
                          privacy of every person who visits, registers with or
                          subscribes to our websites and online publications
                          (“you”), and are committed to ensuring a safe online
                          experience.
                        </p>
                        <p>
                          This Privacy Statement outlines the information we may
                          collect about you in relation to your use of our
                          websites and related publications and services and how
                          we may use that personal data. It also outlines the
                          methods by which we and our service providers may
                          (subject to necessary consents) monitor your online
                          behaviour in order to deliver customised
                          advertisements and marketing materials and other
                          tailored services.
                        </p>
                        <p>
                          This Privacy Statement applies to all websites
                          operated by us (as indicated on the relevant website)
                          (” Sites “). For the purpose of this Privacy
                          Statement, “group company” shall mean Navigate
                          Commodities Pte. Ltd. and any of its subsidiary
                          companies. Please note that the Sites may contain
                          links to external sites and may contain advertisements
                          for, and/or the opportunity for you to purchase
                          products or services from third parties. This Privacy
                          Statement does not cover the activities of such third
                          parties, and you should consult those third-party
                          sites` privacy policies for information on how your
                          data is used by them.
                        </p>
                        <p>
                          By accessing and using the Sites you are agreeing to
                          the terms of this Privacy Statement.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='1'
                  id='header1'
                  onClick={() => {
                    setCurrentId(1)
                  }}
                >
                  <TermsCollapse
                    Header={'Information about us'}
                    Details={
                      <>
                        <p>Our principal business activities are:</p>
                        <p className='l_span'>
                          Business-to-Business Commodity & Financial Publishing.
                          We provide a range of products and services focused on
                          international finance, metals, commodities, telecoms
                          and emerging markets including magazines, newsletters,
                          electronic information and data
                        </p>
                        <p className='l_span'>
                          Organisers of Seminars, Conferences, Training Courses
                          and Exhibitions for the commodities & financial
                          markets industry
                        </p>
                        <p>
                          Navigate Commodities Pte. Ltd.
                          <br />
                          5 Shenton Way,
                          <br />
                          UIC Building, #10-01,
                          <br />
                          Singapore 068808
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='2'
                  id='header2'
                  onClick={() => {
                    setCurrentId(2)
                  }}
                >
                  <TermsCollapse
                    Header={'Name of the Data Controller'}
                    Details={
                      <>
                        <p>
                          The Data Controllers are Navigate Commodities Pte.
                          Ltd.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='3'
                  id='header3'
                  onClick={() => {
                    setCurrentId(3)
                  }}
                >
                  <TermsCollapse
                    Header={'Collection of Personal Data'}
                    Details={
                      <>
                        <p>
                          Our primary goal in collecting personal data from you
                          is to give you an enjoyable customised experience
                          whilst allowing us to provide services and features
                          that most likely meet your needs.
                        </p>
                        <p>
                          We collect certain personal data from you, which you
                          give to us when using our Sites and/or registering or
                          subscribing for our products and services. However, we
                          also give you the option to access our Sites` home
                          pages without subscribing or registering or disclosing
                          your personal data.
                        </p>
                        <p>
                          We also collect certain personal data from other group
                          companies to whom you have given information through
                          their websites (including, by way of example, Navigate
                          Commodities Pte. Ltd., in accordance with the purposes
                          listed below).
                        </p>
                        <p>
                          Please note that we do not intend to collect any
                          personal data from children under thirteen years of
                          age and no child under thirteen should submit any
                          personal data to any of the Sites. Should we discover
                          that any such personal data has been delivered to any
                          of the Sites, we will remove that information as soon
                          as possible.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='4'
                  id='header4'
                  onClick={() => {
                    setCurrentId(4)
                  }}
                >
                  <TermsCollapse
                    Header={'Purpose of Data Collected'}
                    Details={
                      <>
                        <p>
                          We set out in the table below all the ways we plan to
                          use your personal information and the legal basis we
                          rely on to do so. We also explain what our legitimate
                          interests are where appropriate:
                        </p>
                        <p>
                          <Table bordered responsive>
                            <thead>
                              <tr>
                                <th width='184'>Purpose</th>
                                <th width='217'>
                                  Lawful basis for processing including our
                                  legitimate interests
                                </th>
                                <th width='232'>
                                  Processing necessary to achieve purpose
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>To on-board you as a new customer</td>
                                <td>
                                  To fulfil our contract with you <br />
                                  <br />
                                  To fulfil our legal obligations to prevent
                                  financial crime
                                </td>
                                <td>
                                  Collection <br />
                                  <br />
                                  Storage <br />
                                  <br />
                                  Organisation
                                  <br />
                                  <br />
                                  Transfer
                                  <br />
                                  <br />
                                  Disclosure
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  To monitor your use of the Subscription
                                  Service and your license(s) on an on-going
                                  basis
                                </td>
                                <td>
                                  Necessary for our legitimate interests – for
                                  billing purposes <br />
                                  <br />
                                  To fulfil our contract with you
                                </td>
                                <td>
                                  Storage
                                  <br />
                                  <br />
                                  Organisation
                                  <br />
                                  <br />
                                  Transfer
                                  <br />
                                  <br />
                                  Retrieval
                                  <br />
                                  <br />
                                  Consultation
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  To contact you about other services or
                                  products via promotional materials or other
                                  media
                                </td>
                                <td>
                                  Necessary for our legitimate interests – to
                                  develop our products and services and grow our
                                  business
                                </td>
                                <td>
                                  Collection
                                  <br />
                                  <br />
                                  Storage
                                  <br />
                                  <br />
                                  Organisation
                                  <br />
                                  <br />
                                  Retrieval
                                  <br />
                                  <br />
                                  Consultation
                                  <br />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  To notify you about changes to our Sales
                                  Order, Terms of Business or Privacy Statement,
                                  and to communicate with you in response to any
                                  of your queries or requests
                                </td>
                                <td>
                                  To fulfil our contract with you <br />
                                  <br />
                                  To fulfil our regulatory obligations
                                </td>
                                <td>
                                  Collection
                                  <br />
                                  <br />
                                  Storage
                                  <br />
                                  <br />
                                  Organisation
                                  <br />
                                  <br />
                                  Retrieval
                                  <br />
                                  <br />
                                  Consultation
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='5'
                  id='header5'
                  onClick={() => {
                    setCurrentId(5)
                  }}
                >
                  <TermsCollapse
                    Header={'Types of Personal Data Held and its Use'}
                    Details={
                      <>
                        <p>
                          <strong>
                            1. Customer Services and Administration
                          </strong>
                        </p>
                        <p>
                          At some Sites, Navigate Commodities Pte. Ltd. collects
                          personal data such as your name, job title, work
                          and/or home address, and telephone number and email
                          address in order to register you for access to certain
                          content and subscriptions. This information may be
                          supplemented with demographic information from your
                          use of our Sites such as your postal area, age,
                          gender, purchasing preferences and interests.
                        </p>
                        <p>
                          At other Sites, Navigate Commodities Pte. Ltd. may
                          only collect broad demographic information for
                          aggregate use.
                        </p>
                        <p>2. Monitoring use of our Sites</p>
                        <p>
                          Where, as part of our Site services, we enable you to
                          post information or materials on our Site, we may
                          access and monitor any information which you upload or
                          input, including in any password-protected sections.
                          Subject to any necessary consents, we also monitor
                          and/or record the different Sites you visit, and
                          actions taken on those Sites, e.g. content viewed or
                          searched for. If you are a registered user (e.g. a
                          subscriber or taking a trial), when you log on, this
                          places a cookie on your machine. This enables your
                          access to content and services that are not publicly
                          available. Once you are logged on, the functional
                          actions you take – for example, viewing an article –
                          may be recorded.
                        </p>
                        <p>3. Cookies and similar technologies</p>
                        <p>
                          All our Sites use cookies and similar technical tools
                          to collect information about your access to the Site
                          and the services we provide.
                        </p>
                        <p>
                          Cookies are pieces of information which include a
                          unique reference code that a website transfers to your
                          device to store and sometimes track information about
                          you. A number of cookies we use last only for the
                          duration of your web session and expire when you close
                          your browser. Other cookies are used to remember you
                          when you return to the Site and will last for longer.
                          Cookies cannot be used to run programs or deliver
                          viruses to your computer. They are uniquely assigned
                          to you and can only be read by a Web server in the
                          domain that issued the cookie.
                        </p>
                        <p>We may use cookies to:</p>
                        <p>
                          – remember that you have used the Site before; this
                          means we can identify the number of unique visitors we
                          receive to different parts of the Site. This allows us
                          to make sure we have enough capacity for the number of
                          users that we get and make sure that the Site runs
                          fast enough;{' '}
                        </p>
                        <p>
                          – remember your login session so you can move from one
                          page to another within the Site; or store your
                          preferences or your user name and password so that you
                          do not need to input these every time you visit the
                          Site;
                        </p>
                        <p>
                          – customise elements of the layout and/or content of
                          the pages of Site for you;
                        </p>
                        <p>
                          – record activity on our Sites so that we understand
                          how you use our Sites enabling us to better tailor our
                          content, services and marketing to your needs;
                        </p>
                        <p>
                          – collect statistical information about how you use
                          the Site so that we can improve the Site; and
                        </p>
                        <p>
                          – gather information about the pages on the Site that
                          you visit, and also other information about other
                          websites that you visit, so as to place you in a
                          “market segment”. This information is only collected
                          by reference to the IP address that you are using, but
                          does include information about the county and city you
                          are in, together with the name of your internet
                          service provider. This information is then used to
                          place interest-based advertisements on the Site which
                          it is believed will be relevant to your market
                          segment. Some of the cookies used by our Sites are set
                          by us, and some are set by third parties who are
                          delivering services (such as interest-based
                          advertising directed at your market segment) on our
                          behalf
                        </p>
                        <p>
                          Most web browsers automatically accept cookies but, if
                          you prefer, you can change your browser to prevent
                          that or to notify you each time a cookie is set. You
                          can also learn more about cookies in general by
                          visiting{' '}
                          <a
                            href='https://allaboutcookies.org/'
                            target='_blank'
                          >
                            www.allaboutcookies.org
                          </a>{' '}
                          which includes additional useful information on
                          cookies and how to block cookies using different types
                          of browser. Please note however, that by blocking,
                          deleting or turning off cookies used on the Site you
                          may not be able to take full advantage of the Site.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='6'
                  id='header6'
                  onClick={() => {
                    setCurrentId(6)
                  }}
                >
                  <TermsCollapse
                    Header={'Email tracking'}
                    Details={
                      <>
                        <p>
                          So that we can better understand our users` needs, we
                          track responses to our emails – for example, to see
                          which links are the most popular in newsletters, and
                          to log and follow up responses to our marketing
                          messages. To do this, we use pixel GIFs, also known as
                          “pixel tags” – these are small image files that are
                          placed within the body of our email messages. They
                          enable us to tell if a message has been opened and to
                          track click-throughs on links within the message.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='7'
                  id='header7'
                  onClick={() => {
                    setCurrentId(7)
                  }}
                >
                  <TermsCollapse
                    Header={'Consents and opt-outs'}
                    Details={
                      <>
                        <p>
                          You can give your consent to or opt out of particular
                          uses of your data as indicated above by:
                        </p>
                        <p className='l_span'>
                          Indicating at the point on the relevant Site where
                          personal data is collected;
                        </p>
                        <p className='l_span'>
                          Informing us by email, post or phone; or
                        </p>
                        <p className='l_span'>
                          Updating your preferences on the applicable Site;
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='8'
                  id='header8'
                  onClick={() => {
                    setCurrentId(8)
                  }}
                >
                  <TermsCollapse
                    Header={'Marketing Communication'}
                    Details={
                      <>
                        <p>
                          We, our group companies or other third-party service
                          providers may send you marketing communications if you
                          have agreed to receive such communications and in each
                          case you have not opted-out of receiving such
                          communications, or you have asked for information
                          about us and requested we keep you updated about our
                          business and services. We believe as a commercial
                          enterprise we do have a legitimate interest in
                          contacting you about our products or services by
                          utilizing your personal data, and we will only do so
                          if we decide it would be of interest or beneficial for
                          you.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='9'
                  id='header9'
                  onClick={() => {
                    setCurrentId(9)
                  }}
                >
                  <TermsCollapse
                    Header={'Disclosures'}
                    Details={
                      <>
                        <p>
                          Information collected at one Site may be shared
                          between Navigate Commodities Pte. Ltd. and other group
                          companies for the purposes listed above (“purposes of
                          data collected”).
                        </p>
                        <p>
                          We may also disclose your personal data to other third
                          parties, including, without limitation, professional
                          advisers, or governmental or State institutions or
                          regulatory authorities, where necessary in order to
                          exercise or defend legal rights or where required by
                          law.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='10'
                  id='header10'
                  onClick={() => {
                    setCurrentId(10)
                  }}
                >
                  <TermsCollapse
                    Header={'Public forums, message boards and blogs'}
                    Details={
                      <>
                        <p>
                          Some of our Sites make message boards, blogs or other
                          facilities for user generated content available and
                          users can participate in these facilities. Any
                          information that is disclosed in these areas becomes
                          public information and you should always be careful
                          when deciding to disclose your personal information.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='11'
                  id='header11'
                  onClick={() => {
                    setCurrentId(11)
                  }}
                >
                  <TermsCollapse
                    Header={
                      'Confidentiality and Security of Your Personal Data'
                    }
                    Details={
                      <>
                        <p>
                          We are committed to keeping the data you provide us
                          secure and will take reasonable precautions to protect
                          your personal data from loss, misuse or alteration.
                        </p>
                        <p>
                          The transmission of information via the internet is
                          not completely secure. Although we will do our best to
                          protect your personal data, we cannot guarantee the
                          security of your data transmitted to our Site; any
                          transmission is at your own risk. Once we have
                          received your information, we will use strict
                          procedures and security features described above to
                          try to prevent unauthorised access.
                        </p>
                        <p>
                          We have implemented information security policies,
                          rules and technical measures to protect the personal
                          data that we have under our control from:
                        </p>
                        <p className='l_span'>unauthorised access</p>
                        <p className='l_span'>improper use or disclosure</p>
                        <p className='l_span'>unauthorised modification</p>
                        <p>
                          All our employees, contractors and data processors
                          (i.e. those who process your personal data on our
                          behalf, for the purposes listed above), who have
                          access to, and are associated with the processing of
                          your personal data, are obliged to keep the
                          information confidential and not use it for any other
                          purpose than to carry out the services they are
                          performing for us.
                        </p>
                        <p>
                          We also give you the option of using a secure
                          transmission method to send us personal data
                          identifiers, such as credit card details and bank
                          account number.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='12'
                  id='header12'
                  onClick={() => {
                    setCurrentId(12)
                  }}
                >
                  <TermsCollapse
                    Header={'Data Retention'}
                    Details={
                      <>
                        <p>
                          We will only retain your personal data for as long as
                          reasonably necessary to fulfil the purposes we
                          collected it for, including in order to meet any
                          legal, accounting, or regulatory requirements.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='13'
                  id='header13'
                  onClick={() => {
                    setCurrentId(13)
                  }}
                >
                  <TermsCollapse
                    Header={'Your Rights and Your Personal Data'}
                    Details={
                      <>
                        <p>
                          If you wish to know whether we are keeping personal
                          data about you, or if you have an enquiry about our
                          privacy policy or your personal data held by us, in
                          relation to any of the Sites, you can contact the Data
                          Protection Officer via:
                        </p>
                        <p className='l_span'>
                          Postal mail to this address: Data Protection Officer,
                          Navigate Commodities Pte. Ltd., 5 Shenton Way, UIC Building, #10-01, Singapore 068808
                        </p>
                        <p className='l_span'>Telephone: +65 8318 2835</p>
                        <p className='l_span'>
                          Email:
                          <a href='mailto:compliance@navigatecommodities.com'>
                            compliance@navigatecommodities.com
                          </a>
                        </p>
                        <p>
                          In accordance with the relevant jurisdictional and EU
                          data protection laws, subjects have the right in
                          relation to personal data that includes the right to
                          request access to your personal data, to correct it,
                          have it erased, restrict it from being used, object to
                          it being processed, to the portability of data and to
                          withdraw consent at any time. You can request such
                          rights by mailing, dialling or emailing to the above
                          address, telephone number or email address
                          respectively. We may also need to seek further
                          information from you to confirm your identity before
                          we release any personal information.
                        </p>
                        <p>
                          Any other purposes for which Navigate Commodities Pte.
                          Ltd. wishes to use your personal data will be notified
                          to you and your personal data will not be used for any
                          other such purpose other than as informed in this
                          Privacy Statement, either by notifying you by an
                          update of this Privacy Statement or by obtaining your
                          prior consent.
                        </p>
                        <p>
                          We reserve the right to transfer, sell or assign any
                          of the information described in this policy to third
                          parties as a result of a sale, merger, consolidation,
                          change of control, transfer of assets or
                          reorganisation of our business.
                        </p>
                      </>
                    }
                  />
                </Accordion.Item>
                <Accordion.Item
                  eventKey='14'
                  id='header14'
                  onClick={() => {
                    setCurrentId(14)
                  }}
                >
                  <TermsCollapse
                    Header={'Changes to this Privacy Statement'}
                    Details={
                      <>
                        <p>
                          We will occasionally update this Privacy Statement to
                          reflect new legislation or industry practice, group
                          company changes and customer feedback. We encourage
                          you to review this Privacy Statement periodically to
                          be informed of how we are protecting your personal
                          data.
                        </p>
                        <p>
                          <strong>
                            This Privacy Statement was last updated in July
                            2021.
                          </strong>
                        </p>
                        <p></p>
                      </>
                    }
                  />
                </Accordion.Item>
              </Accordion>
            </section>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Privacypolicy
