import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Demobanner from '../../components/demobanner/Demobanner'
import Categories from '../../components/blog-post/categories-search'
import Postbanner from '../../components/blog-post/post-banner-img'
import BlogShare from '../../components/blog-post/blog-share'

import '../../components/blog-post/blog-post.css'
import blog_logo from '../../images/blogs/reuters2.png'
import Placeholderimg from '../../images/blogs/placeholderimg.jpg'
import LinkedIn_icon from '../../images/blogs/icons/bxl-linkedin.svg'
import Instagram_icon from '../../images/blogs/icons/Vector.svg'
import Twitter_icon from '../../images/blogs/icons/twitter.svg'
import { useEffect, useState } from 'react'
import { useLocation,useParams } from 'react-router-dom'
import { mediaApi } from './api'
import { utilities } from '../utlilities/utils'
import HtmlParser from 'react-html-parser'

const BlogMediaDetails = ({ type }) => {
  const paramters = useParams()
  const {pathname}= useLocation()
  const [details, setDetails] = useState({})
  const imageUrl=process.env.REACT_APP_IMAGE_URL;


  useEffect(() => {
    getMediaBlogDetails()
  }, [])
  // <-------------------------USER Define Function------------------------>
  const getMediaBlogDetails = index => {
    let url = null
    if (type == 'blog') {
      url = `/website/blog-by-id`
    } else {
      url = `/website/media-coverage-by-id`
    }
    mediaApi?.getBlogMediaById(url, { id: paramters?.id }, (err, data) => {
      if (err == null) {
        setDetails(data?.data?.data)
      }
    })
  }

  // <-------------------------End of Define Function------------------------>
  return (
    <section className='main'>
      <section className='blog-post blog-post-details'>
        <Container>
          <Row>
            <Col md={9} xl={10} className='order-last order-md-first'>
              <Row>
                <Col xs={12}>
                  <section className='blog-banner py-0'>
                    <Row className='align-items-center'>
                      <Col xs={12}>
                    
                        {/* <Postbanner image={ pathname.includes('media-details')? market_logo: blog_logo} /> */}
                        <Postbanner image={ pathname.includes('media-details')
                        ? details?.image !== null && details?.image !== undefined && details?.image.includes('https')? details?.image :details?.image==null?Placeholderimg:`${imageUrl}${details?.image}`
                        
                        : details?.image !== null && details?.image !== undefined && details?.image.includes('https')? details?.image :details?.image==null?Placeholderimg:`${imageUrl}${details?.image}`
                        } />

                        <div className='detail-heading'>
                          <h1>{details?.title}</h1>
                        </div>
                        <div className='blog-date'>
                          {details?.date == null
                            ? ' '
                            : utilities?.convertDateToLocal(
                                details?.date
                              )}
                        </div>
                      </Col>
                    </Row>
                  </section>
                  {HtmlParser(details?.content)}
                </Col>
              </Row>
            </Col>
            <Col
              md={3}
              xl={2}
              className='order-firt order-md-last mb-4 mb-md-0'
            >
              {/* <Categories /> */}
              <div className='d-none d-md-block'>
                <BlogShare
                  LinkedIn={LinkedIn_icon}
                  // Instagram={Instagram_icon}
                  twitter={Twitter_icon}
                />
              </div>
            </Col>
            <Col xs={12} className='d-md-none order-last'>
              <BlogShare
                LinkedIn={LinkedIn_icon}
                // Instagram={Instagram_icon}
                twitter={Twitter_icon}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default BlogMediaDetails
