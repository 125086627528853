// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'

import Innertopbanner from '../components/inner-topbanner/Innertopbanner'
import DataFeatures from '../components/data-features/DataFeatures'
import Services from '../components/services/Services'
import Demobanner from '../components/demobanner/Demobanner'

import SupplyDemand from '../images/product/Compass-1.svg'
import SatelliteData from '../images/product/Compass-2.svg'
import Miningdata from '../images/product/Compass-3.svg'
import Market_compass from '../images/inner-banner/Desktop.svg'
import React, { useEffect } from 'react'
const MarketCompass = () => {
  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'instant' })
  }, [])

  return (
    <section className='main'>
      <section className='main-banner inner-banner'>
        <Container className='text-center text-md-start'>
          <Innertopbanner
            header={'Market Compass'}
            pradetails={
              'Powerful combination of real-time data analytics and storytelling to deliver highly accurate award-winning short-term strategic research and forecasts on dry bulk commodity markets.'
            }
            image={
              <img
                className='innerbanner-img navigate_compass'
                src={Market_compass}
                alt='banner_graphics'
              />
            }
          />
        </Container>
      </section>

      <section className='solution data-features py-4 text-center'>
        <DataFeatures
          header={'Data & Features'}
          Pradetails={
            <p className='banner-pra'>
              We combine fundamental market analysis, econometrics,
              metallurgical engineering, financial market experience,
              <br /> and technical analysis to generate &gt; 81% forecast
              accuracy.
            </p>
          }
        />
      </section>

      <section className='middle-top market_compass py-3'>
        <Container className='text-cetner text-md-start'>
          <Services
            image={SupplyDemand}
            header={'Weekly Market Analytics'}
            pra={
              'Real-time monitoring of dry bulk commodity supply chains enables us to provide unrivalled analysis & insights that you won’t find anywhere else on the planet.'
            }
          />
          <Services
            image={SatelliteData}
            header={'Interpretation of Real-Time Data'}
            pra={
              'We interpret unique high-value, high-frequency real-time data into true actionable tradeable ideas for those companies that lack analysis or research capabilities.  '
            }
          />
          <Services
            image={Miningdata}
            header={'Award-Winning Storytelling'}
            pra={
              'Voted “Best in Class” for our Iron Ore Research in 2022 & 2023 by industry, the pedigree & quality of our analysis encompasses all the commodities we cover.'
            }
          />
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default MarketCompass
