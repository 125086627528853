import { useEffect } from 'react'
import BlogMedia from '../components/blog-media/index'

const Blog = () => {
  useEffect(() => {
    setTimeout(() => {
    window?.scrollTo({top:0, left:0 , behavior:'instant'})
    }, 250);
  }, [])
  return <BlogMedia type='blog'></BlogMedia>
}

export default Blog
