import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import React from 'react'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import './about.css'
import AboutBanner1 from '../../images/about/OurJourney/Photo-1.png'
import AboutBanner2 from '../../images/about/OurJourney/Photo-2.png'
import AboutBanner3 from '../../images/about/OurJourney/Photo-3.png'


const Ourstory = () => {
  const { id } = useParams()
    
  React.useEffect(() => {
    console.log(id)
    if (id != null && id != undefined) {
      document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' })
    }
  }, [id]);

  


  return (
    <section className='Ourstory ' id='our-journey'>
       <section className ='main-banner about-banner'>
                <Container>
                    <Row className = "align-items-center">
                        <Col md = {6} className = "mb-3 mb-md-0">
                            <h1 className = "mb-3">
                                Our Journey
                            </h1>
                            <h2>How it started</h2>
                            <p>
                            In 2020, the Navigate Commodities crew identified an opportunity to bring real-time Earth Observation technology to the heavily underserviced Metals & Mining sector.
                            </p>
                            <p>
                            As a new breed of intelligence agency, the team felt it was their responsibility to address the huge information chasm and severe lack of transparency in opaque commodity markets.
                            </p>
                        </Col>
                        <Col md = {{span: 5, offset: 1}}>
                            <img src = {AboutBanner1} className = "w-100" alt = "banner-img"/>
                        </Col>
                    </Row>

                    <Row className = "align-items-center py-3 py-lg-5">
                        <Col xs={{span:12,order:2}} md = {{span:5 ,order:1}}  >
                            <img src = {AboutBanner2} className = "w-100" alt = "banner-img"/>
                        </Col>
                        <Col md = {{span: 6, offset: 1,order:2}} className = "mb-3 mb-md-0 order-sm-1">
                            <h2>
                            How  it’s  going
                            </h2>
                            <p>
                            Our entrepreneurs have successfully married deep dry bulk commodity subject matter expertise with top tier data- and tech-heavy infrastructure to deliver state-of-the-art services.
                            </p>
                            <p>
                            Much of the company’s success has been achieved in non-traditional markets by listening to and understanding the pain & pressure points of our customers.
                            </p>
                        </Col>
                    </Row>

                    <Row className = "align-items-center py-3">
                        <Col md = {6} className = "mb-3 mb-md-0">
                            <h2>
                            Where we’re going
                            </h2>
                            <p>
                            The goal has always been to digitise the monitoring of dry bulk commodities and democratise investment banking-quality intelligence so SMEs could compete with larger industry peers.
                            </p>
                            <p>
                            Grateful for the trust & confidence of our growing & loyal customer base, Navigate Commodities will continue to serve industry by funding the development of tech to address market inefficiencies.
                            </p>
                        </Col>
                        <Col md = {{span: 5, offset: 1}}>
                            <img src = {AboutBanner3} className = "w-100" alt = "banner-img"/>
                        </Col>
                    </Row>
                </Container>
            </section>

      <section className='ourstory_timeline position-relative'>
        <Container>
          <Row>
            <Col sm={12}>
              <div className='timeline-container position-relative'>
                <div className='timeline-content right'>
                  <h2>Apr 2020</h2>
                  <p>
                  Navigate Commodities founded and incorporated in Singapore
                  </p>
                </div>
                <div className='timeline-content left'>
                  <h2>May 2020</h2>
                  <p>
                  Navigate Commodities awarded Startup Founder SG grant <br/>by Enterprise Singapore
                  </p>
                </div>
                <div className='timeline-content right'>
                  <h2>Apr 2021</h2>
                  <p>Commercial “Go Live” launch of Navigate Commodities</p>
                </div>
                <div className='timeline-content left'>
                  <h2>Jun 2021</h2>
                  <p>Launched Navigate Marine Iron Ore – real-time monitoring of bulk Iron Ore cargoes</p>
                </div>
                <div className='timeline-content right'>
                  <h2>Apr 2022</h2>
                  <p>
                  Voted “Best Iron Ore Research” by industry in EnergyRisk Commodity Rankings 2022
                  </p>
                </div>
                <div className='timeline-content left'>
                  <h2>Jun 2022</h2>
                  <p>
                  Launched Navigate Marine Ferrous Scrap – real-time monitoring of bulk Ferrous Scrap cargoes
                  </p>
                </div>
                <div className='timeline-content right'>
                  <h2>Sep 2022</h2>
                  <p>Launched world’s first AI-powered WhatsApp-native commodity data bot</p>
                </div>
                <div className='timeline-content left'>
                  <h2>Oct 2022</h2>
                  <p>Pre-Series A funding of SGD500,000 from Stellar James Management and Palmm</p>
                </div>
                <div className='timeline-content right'>
                  <h2>Feb 2023</h2>
                  <p>
                  Launched partnership with Earth-i on real-time monitoring of global Steel industry
                  </p>
                </div>
                <div className='timeline-content left'>
                  <h2>Mar 2023</h2>
                  <p>Voted “Best Iron Ore Research” by industry in EnergyRisk Commodity Rankings 2023</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default Ourstory
