import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "./top-banner.css";
// import Animationgif from '../../images/home/steelmillheat.svg';
// import Arrow_right from '../../images/home/arrow-right.svg';
import Animation_mp from "../../images/home/videos/animation1.mp4";

const Topbanner = () => {
  return (
    <section className="main-banner">
      <Container className="text-center text-md-start">
        <Row className="align-items-center">
          <Col md={6} className="mb-3 mb-md-0">
            <h1>Creating Total Transparency <br/>in Opaque Commodity Markets</h1>
            <p className="banner-pra">
              Proprietary Maritime & Satellite technology that enables you to
              monitor your clients, competitors, and vendors in real time.
            </p>
            <Row className="align-items-center d-none d-md-flex">
              <Col xs="12">
                <Link to="/request-demo" className="btn-main">
                  Get Started
                </Link>
                <Link
                  to="/methodology"
                  state={{ fromlink: "viewsolution" }}
                  className="btn-transparent ms-2"
                >
                  View Solutions <i className="ms-2 bi bi-arrow-right"></i>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={{ span: 5, offset: 1 }} className="text-end">
            {/* <img className='w-75' src = {Animationgif} alt = "banner-animation"/> */}
            <video width="100%" height="490" loop autoPlay={true} muted={true}>
              <source src={Animation_mp} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Topbanner;
