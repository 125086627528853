// import { NavLink, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Demobanner from '../components/demobanner/Demobanner'

const Contact = () => {
  React.useEffect(() => {
    window.scrollTo({top:0 , left:0 , behavior : "instant"})
  }, [])

  return (
    <section className='main'>
      <section className='main-banner inner-banner center-top-banner contactUs-banner'>
        <Container className='text-center'>
          <Row className='align-items-center'>
            <Col md={12}>
              <h1>We’d Love to hear from you</h1>
              <p className='banner-pra'>
                Whether you have questions about our services, subscription
                options or the markets, we are ready to answer and support you.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className='mt-4 mt-md-5'>
          <Row className='jsutify-content-center'>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <div className='contact-card phone'>
                <h2>Phone</h2>
                <p>
                  To reach us directly, pick up the phone and start dialing.
                </p>
                <div className='contact-link'>
                  <a href='#'>+65 8692 1718</a>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <div className='contact-card email'>
                <h2>Email</h2>
                <p>
                  Send an email to this dedicated mailbox and we will respond
                  promptly.
                </p>
                <div className='contact-link'>
                  <a href='mailto:virtual.secretary@navigatecommodities.com'>
                    @Virtual Secretary
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <div className='contact-card media'>
                <h2>Media</h2>
                <p>
                  Reach out to us for an unfettered, honest assessment, comment,
                  opinion, quote or sound bite.
                </p>
                <div className='contact-link'>
                  <a href='mailto:media@navigatecommodities.com'>
                    @Media Contact
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6} lg={3} className='mb-3 mb-lg-0'>
              <div className='contact-card partnership'>
                <h2>Partnerships</h2>
                <p>
                  We believe in building collaborative, mutually beneficial
                  partnerships where everyone wins. We don't see walls, only
                  bridges
                </p>
                <div className='contact-link'>
                  <a href='mailto:collaborator@navigatecommodities.com'>
                    @Partnership
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='Address_details'>
        <Container>
          <h2>Visit us at our Headquarters</h2>
          <Row className='align-items-center'>
            <Col md='5' className='mb-3 mb-md-0'>
              <p>
                Pay us a visit to discuss commodities or our services over a
                freshly brewed cup of coffee
              </p>
              <div className='location-add'>
                5 Shenton Way, UIC Building, #10-01, Singapore 068808
              </div>
            </Col>
            <Col md='7'>
              <div className='location-map'>
                {/* <img className = "w-100" src = {Location_map} alt = "location"/> */}
                <div className='map-container'>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3988.8270596998027!2d103.84792604173056!3d1.2772149515312878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x112c965d49ac971e!2sNavigate%20Commodities!5e0!3m2!1sen!2ssg!4v1609729536034!5m2!1sen!2ssg'
                    width='100%'
                    height='100%'
                  ></iframe>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Demobanner />
    </section>
  )
}

export default Contact
