import { Search } from 'react-bootstrap-icons'

const Categories = ({ searchMediaBlog, query }) => {
  console.log(query)
  return (
    <div className='categories-search'>
      <h2>Search</h2>
      <div className='inputsearch'>
        <div className='input-group'>
          <span className='input-group-text' id='basic-addon1'>
            <Search />
          </span>
          <input
            type='text'
            className='form-control'
            placeholder=' Search'
            value={query}
            onChange={e => {
              searchMediaBlog(e?.target?.value)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Categories
