import { Link, NavLink, Navigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import React, { useState, useRef, useEffect } from 'react'

import Navigate_logo from '../../images/logos/Navigate_Commodities_logo_final-3484px.png'

import Earth_icon from '../../images/navbar-icons/navigate-earth.svg'
import Marine_icon from '../../images/navbar-icons/marine.svg'
import WhatsApp_icon from '../../images/navbar-icons/WhatsApp.svg'
import Interacitve_icon from '../../images/navbar-icons/intractive.svg'
import Compass_icon from '../../images/navbar-icons/bx-compass.svg'
import Methodology_icon from '../../images/navbar-icons/methodology.svg'
import DataCoverage_icon from '../../images/navbar-icons/data-coverage.svg'
import Integrations_icon from '../../images/navbar-icons/integrations.svg'
import Our_story from '../../images/navbar-icons/our-story.svg'
import Leadership_icon from '../../images/navbar-icons/leadership.svg'
import Our_company from '../../images/navbar-icons/our-company.svg'
import Achievements_icon from '../../images/navbar-icons/achievements.svg'
import Blogs_icon from '../../images/navbar-icons/blogs.svg'
import Media_icons from '../../images/navbar-icons/Media.svg'
import Contact_icon from '../../images/navbar-icons/contact.svg'
import Demo_icon from '../../images/navbar-icons/Demo.svg'
import Linkedin_icons from '../../images/navbar-icons/linkedin.svg'
import Youtube_icons from '../../images/navbar-icons/youtube.svg'

import AngleDown_icons from '../../images/navbar-icons/angle-down.svg'

import './header.css'

const Header = () => {
  const [isActive, setActive] = useState(false)
  const [currentTab, setCurrentTab] = useState()
  const [isScroll, setIsScroll] = useState(false)
  const [expanded, setExpanded] = useState(false);

  const toggleClass = () => {
    setActive(!isActive)
    setCurrentTab(!currentTab)
  }



  window?.addEventListener('scroll', () => {
    if (window?.screenY > 5) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  })

  return (
    <Navbar
      expanded={expanded}
      bg='white'
      expand='lg'
      className={isScroll ? 'top-navbar scroll-top-navbar ' : 'top-navbar'}
    >
      <Container>
        <div className='position-relative d-flex align-items-center justify-content-between w-100 d-lg-none'>
          <Navbar.Brand href='/' className='py-0'>
            <img src={Navigate_logo} alt='logo' />
          </Navbar.Brand>
          <a href='https://members.navigatecommodities.com/' className='btn-default btn-default-mob'>
            Login
          </a>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            className={isActive ? 'open-active' : null}
            onClick={() => { toggleClass(); setExpanded(expanded ? false : "expanded") }}
          />
        </div>
        <Navbar.Brand href='/' className='d-none d-lg-block py-0'>
          <img src={Navigate_logo} alt='logo' />
        </Navbar.Brand>
        <Nav className='ms-lg-auto'>

          <Navbar.Collapse id='basic-navbar-nav'>
            <ul className='nav'>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Products'
                    ? setCurrentTab('')
                    : setCurrentTab('Products')
                }}
              >
                <Link to='#'>
                  Products <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${currentTab == 'Products' ? 'open' : ''
                    } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Earth_icon} alt='icons' />
                        </div>
                        <h3>

                          <Link to='/navigate-earth' onClick={() => { setExpanded(false); setActive(false) }}>Navigate Earth</Link>
                        </h3>
                        <p>Monitor Metal Smelting Activity from Space</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Marine_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/navigate-marine' onClick={() => { setExpanded(false); setActive(false) }}>Navigate Marine</Link>
                        </h3>
                        <p>Monitor Dry Bulk Commodities at Sea</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Compass_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/market-compass' onClick={() => { setExpanded(false); setActive(false) }}>Market Compass</Link>
                        </h3>
                        <p>Dry Bulk Commodity Analysis & Forecasts</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={WhatsApp_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/whatsapp-ai' onClick={() => { setExpanded(false); setActive(false) }}>WhatsApp AI (NAVI)</Link>
                        </h3>
                        <p>
                          Query Trade Flows & Production <br /> On-Demand
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Interacitve_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/interactive-directory' onClick={() => { setExpanded(false); setActive(false) }}>
                            Interactive Directory
                          </Link>
                        </h3>
                        <p>
                          Find Ferrous & <br /> Non-Ferrous Scrap processors
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Technology'
                    ? setCurrentTab('')
                    : setCurrentTab('Technology')
                }}
              >
                <Link to='#'>
                  Technology <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${currentTab == 'Technology' ? 'open' : ''
                    } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Methodology_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/methodology' onClick={() => { setExpanded(false); setActive(false) }}>Methodology</Link>
                        </h3>
                        <p>
                          The technology that powers our data engine
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Integrations_icon} alt='icons' />
                        </div>
                        <h3>

                          <Link to="/methodology/integration" onClick={() => { setExpanded(false); setActive(false) }}>Integrations</Link>
                        </h3>
                        <p>Flexible data & information delivery mechanisms </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={DataCoverage_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/data-coverage' onClick={() => { setExpanded(false); setActive(false) }}>Data Coverage</Link>
                        </h3>
                        <p>Existing Sector Coverage & what’s Coming Soon</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'About'
                    ? setCurrentTab('')
                    : setCurrentTab('About')
                }}
              >
                <Link to='#'>
                  About <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${currentTab == 'About' ? 'open' : ''
                    } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Our_story} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/our-journey' onClick={() => { setExpanded(false); setActive(false) }}>
                            Our Journey
                          </Link>
                        </h3>
                        <p>Our Past, Present & Future</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Leadership_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/leadership' onClick={() => { setExpanded(false); setActive(false) }}>Our Leadership</Link>
                        </h3>
                        <p>The people driving progress & breaking barriers</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Our_company} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/company' onClick={() => { setExpanded(false); setActive(false) }}>Our Company</Link>
                        </h3>
                        <p>
                          Building products through strong partnerships
                        </p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Achievements_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/about/our-achievements' onClick={() => { setExpanded(false); setActive(false) }}>Our Achievements</Link>
                        </h3>
                        <p>Accomplishments & milestones</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Insights'
                    ? setCurrentTab('')
                    : setCurrentTab('Insights')
                }}
              >
                <Link to='#'>
                  Insights <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${
                    currentTab == 'Insights' ? 'open' : ''
                  } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Blogs_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/blog'  onClick={() => {setExpanded(false); setActive(false)}}>Blog </Link>
                        </h3>
                        <p>Read some of our Award-Winning Insights</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Media_icons} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/media-coverage'  onClick={() => {setExpanded(false); setActive(false)}}>Media Coverage</Link>
                        </h3>
                        <p>Where we’ve been mentioned in the media</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              <li
                className='nav-item dropdown-mn'
                onClick={() => {
                  currentTab == 'Contact'
                    ? setCurrentTab('')
                    : setCurrentTab('Contact')
                }}
              >
                <Link to='#'>
                  Contact <img src={AngleDown_icons} alt='Angle-down' />
                </Link>
                <div
                  className={`${currentTab == 'Contact' ? 'open' : ''
                    } dropdown-content`}
                >
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Contact_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/contact' onClick={() => { setExpanded(false); setActive(false) }}>Contact Us</Link>
                        </h3>
                        <p>Reach out to our friendly team ready to help</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Demo_icon} alt='icons' />
                        </div>
                        <h3>
                          <Link to='/request-demo' onClick={() => { setExpanded(false); setActive(false) }}>Request Demo</Link>
                        </h3>
                        <p>Schedule a meeting to see how the products work</p>
                        {/* <Link to='#'>Schedule a meeting </Link>
                        <Link to='#'>to see how the</Link>
                        <Link to='#'>products work</Link> */}
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Linkedin_icons} alt='icons' />
                        </div>
                        <h3>
                          <a
                            href='https://www.linkedin.com/company/navigate-commodities-pte-ltd/'
                            target='_blank'
                          >
                            LinkedIn
                          </a>
                        </h3>
                        <p>Learn more about us by connecting on social</p>
                      </div>
                      <div className='col'>
                        <div className='col-icon'>
                          <img src={Youtube_icons} alt='icons' />
                        </div>
                        <h3>
                          <a
                            href='https://www.youtube.com/@navigatecommodities3727'
                            target='_blank'
                          >
                            YouTube
                          </a>
                        </h3>
                        <p>Watch our creative unique videos</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className='nav-item login-link'>
                <a href='https://members.navigatecommodities.com/' className='btn-default'> Login</a>
              </li>
              <li className='nav-item'>
                <Link to='/request-demo' className='btn-main' onClick={() => { setExpanded(false); setActive(false) }}>
                  Request Demo
                </Link>
              </li>
            </ul>
          </Navbar.Collapse>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header
